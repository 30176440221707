@font-face {
  font-family: "VerdanaPro";
  src: url("assets/fonts/VerdanaPro-Regular.ttf") format("truetype");
}


/* styles.scss */


/* You can add global styles to this file, and also import other style files */

@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import '../node_modules/@swimlane/ngx-datatable/themes/dark.scss';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

* {
  font-family: "VerdanaPro", sans-serif;
}

body {
  margin: 0;
}


/* You can add global styles to this file, and also import other style files */


/* You can add global styles to this file, and also import other style files */

.clearfix {
  clear: both;
}

.pointer {
  cursor: pointer;
}

.ucfirst {
  text-transform: capitalize;
}

.min300 {
  min-height: 300px;
}

.app-box {
  padding: 10px;
  margin-bottom: 20px;
  height: 230px;
  background: #b5e8d4;
  overflow: hidden;
  box-shadow: 1px 1px 4px #ccc;
}

.app-box:hover {
  box-shadow: 1px 1px 4px #999;
}

.app-box .remove {
  position: absolute;
  right: 21px;
  top: 0px;
  color: #545454;
  display: none;
}

.app-box:hover .remove {
  display: block;
}

.inline-btn {
  background: #00b970;
  padding: 1px 5px;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
}

.box {
  padding: 10px;
  margin-bottom: 10px;
  overflow: auto;
  box-shadow: 1px 1px 4px #ddd;
}

.dndDropArea {
  padding: 10px;
}

.dndDropArea.empty {
  /* min-height: 100%; */
  height: 100vh;
}

.field {
  /* padding: 5px;
    margin-bottom: 10px; */
  position: relative;
}

.toggle-Wrapper {
  padding: 15px;
  background: #d6d6d629;
  border-radius: 5px;
}

.dndHandle {
  cursor: pointer;
  color: #999;
}

.dndDraggingSource {
  opacity: 0.3;
}

.dndPlaceholder {
  background: rgb(206, 238, 238);
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 10px;
}

.red {
  color: #d9534f;
}

.marginT60 {
  margin-top: 60px;
}

.color-red {
  color: red;
}

.report-block {
  padding: 10px;
  background: #f8f8f8;
  margin-bottom: 20px;
}

.report-block .row:nth-child(even) {
  background: #fff;
}

.ftheme {
  width: 100%;
}

.dndDropArea .field .fa {
  opacity: 0;
}

.dndDropArea .field:hover .fa {
  opacity: 1;
}

.colorpick {
  height: 25px;
  background: white;
  border: none;
}


/*---------------all material custom css---------------*/

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3D854F;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #6ff892;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #3D854F;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
}

.mat-slide-toggle-thumb-container {
  height: 18px !important;
  width: 18px !important;
}

.mat-slide-toggle-bar {
  width: 34px !important;
  height: 13px !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #3D854F;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #fff;
  border: 1px solid#56BD70;
}

.mat-checkbox-checkmark-path {
  stroke: #3D854F !important;
}

.mat-checkbox-checkmark-path {
  stroke-width: 3px !important;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}


/*------Form builder Expression css-------*/

.mat-expansion-panel-header {
  height: unset;
  padding: 10px 20px !important;
  font-size: 14px;
  background-color: #e8e8e8;
}

.mat-expansion-panel-spacing {
  margin-top: 0 !important;
}

.mat-expansion-panel-spacing:first-child {
  margin: 0;
}

.mat-accordion {
  display: flex;
  justify-content: flex-end;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 0 !important;
  margin-bottom: 8px;
  box-shadow: unset;
  border: 1px solid #939393;
  width: 100%;
}

.mat-expansion-panel-header.mat-expanded {
  height: unset;
  background-color: #3D854F !important;
}

.mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-title {
  color: #fff;
}

.side-sub-close {
  position: absolute;
  display: none;
  color: #fff;
  width: 12px;
  top: 15px;
  right: 15px;
}

.mat-expansion-panel-header.mat-expanded .side-sub-close {
  display: block;
}

.mat-expansion-panel-body {
  padding: 5px !important;
  height: 400px;
  overflow-y: scroll;
}

.mat-checkbox-layout {
  margin: 0;
}


/*------Common css for all screens------*/

.all-btns {
  // padding: 8px 20px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
}

.cancel-btn,
.reset-btn {
  border: 1px solid #707070;
}

.create-btn,
.publish-btn,
.varitations-btn,
.upload-btn {
  border: 1px solid #3D854F;
  background-color: #3D854F;
  color: #fff;
  margin-left: 15px;
}

.btn:hover {
  color: #fff;
}

.cancel-btn,
.reset-btn {
  color: #000 !important;
}

.save-btn {
  background-color: #707070;
  color: #fff;
  margin-left: 15px;
  padding: 8px 30px;
}

.view-btn {
  background-color: #282f42;
  color: #fff;
  padding: 8px 30px;
}

.common-int {
  background: #f5f5f5 !important;
  border: 1px solid #cbcbcb;
  width: 100%;
  font-size: 13px;
  color: #707070;
  padding: 5px;
  border-radius: 2px;
}

.sub-search {
  position: relative;
}

.sub-search .search-int {
  width: unset;
  font-size: 12px;
  height: unset;
  padding: 5px 30px 5px 5px;
  border: 1px solid #bfbfbf;
  color: #bfbfbf;
}

.sub-search .search-int-sub {
  position: absolute;
  top: 10px;
  right: 9px;
  color: #bfbfbf;
  font-size: 14px;
}

.setting-inputs {
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 3px;
  font-size: 14px;
}

.all-sections {
  border-bottom: 1px solid #707070;
  padding-bottom: 15px;
}

.dash-select {
  background: #f5f5f5;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: 6px 20px 5px 6px;
}

.moderation-select {
  background: #f5f5f5;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: 6px 20px 5px 6px;
}

.color-m {
  color: #707070;
}

.setting-search,
.tab-section-inputs {
  display: flex;
  align-items: center;
}

.tab-section-inputs {
  justify-content: space-between;
  border-bottom: 2px solid #707070;
  font-size: 12px;
}

.setting-add-btn {
  padding: 5px 25px;
  background: #3D854F;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

p {
  margin: 0;
}


/*--------form-section----------*/


/* 
.commontitle {
    display: flex;
    align-items: center;
} */

.cta-btn {
  display: flex;
  justify-content: flex-end;
}

.int-allow {
  font-size: 14px;
  color: #707070;
}

.slide-toggle-switch {
  margin-bottom: 15px;
  font-size: 14px;
}


/*------settings-section---------*/

.dashboard-main-title {
  display: flex;
  align-items: unset;
  justify-content: space-between;
  margin-bottom: 15px;
}

.dashboard-main-title h5 {
  font-weight: 700;
}

.option-entity {
  background: #f5f5f5;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  font-size: 12px;
  padding: 7px;
}

.ck.ck-toolbar {
  border-color: #707070 !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #707070 !important;
}

.settings-title {
  font-size: 20px !important;
}

.tooltip-list {
  white-space: pre;
}


/*-----Modal css-----*/

.modal.show .modal-dialog {
  /* transform: translate(0%, 100%); */
  margin: 50px auto;
  max-width: 700px;
}

.modal-title {
  font-size: 20px !important;
  margin: 0 !important;
}

.modal-title-body {
  font-size: 14px;
}

.modal-select {
  border: 1px solid #bfbfbf;
  padding: 6px;
  font-size: 13px;
  border-radius: 5px;
  width: 100%;
}

.ng-dropdown-panel {
  background-color: #fff;
}

[type=search] {
  outline-offset: unset;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3D854F;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #3D854F;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #3D854F;
}


/*---------ng dataxtable--------------*/

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: transparent !important;
  color: #000 !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: unset !important;
}

.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  display: flex !important;
  align-items: center !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 10px !important;
}

.submission-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  .submitSub-btn {
    background-color: #3D854F;
    color: #fff;
    margin-left: 15px;
    padding: 8px 25px;
  }
}

.mat-tooltip {
  color: #000 !important;
  font-weight: 500;
  margin: 10px !important;
  padding: 10px 15px !important;
  font-size: 12px;
  background: #3D854F14;
  border: 1px solid #3D854F;
  line-height: 20px;
}

.ck-rounded-corners {
  height: 270px;
}


/*-----Public facing form------*/

.top-bar {
  color: #fff;
  font-weight: 700;
  padding: 15px 0;
  font-size: 14px;
}

.top-section {
  background: #832925;
}

.second-section {
  background-color: #275883;
}

.logo {
  width: 250px;
  margin: 10px 0;
}

.footer-sec {
  background: #2f3f4e;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 10px 0 0;
}

.footer-menu li a {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.footer-menu li a:hover {
  text-decoration: none;
}

.bottom-line {
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.form-drop-area {
  padding: 35px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ededed;
  margin: 50px 0;
  background: #fff !important;

  .form-self-title {
    font-size: 22px;
    font-weight: bold;
    color: #0e3653;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
  }

  .form-self-subtitle {
    font-size: 13px;
    color: #0e3653;
  }

  .form-labels {
    font-size: 13px;
    font-weight: 600;
  }
}

// Material UI CSS
// .field-input-text
.mat-form-field {
  .mat-form-field-wrapper,
  .mat-form-field-infix {
    padding: 0;
    margin: 0;
    border: 0;

    .mat-input-element {
      background-image: none !important;
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 2.4em;
  }

  .mat-form-field-label-wrapper {
    .mat-form-field-label {
      top: 12px;

      .required-field {
        color: red;
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 2000;
}


/*-------all tree node css-------*/

.mat-tree-node {
  min-height: 35px;

  span {
    cursor: pointer;
  }
}

.mat-nested-tree-node li ul {
  padding-left: 25px;
}

.warning-stick {
  display: flex;
  align-items: center;

  .traingle .fa {
    font-size: 18px;
  }

  .triangle-title strong {
    font-size: 13px;
  }
}

.field-input-text {
  label {
    font-size: 13px;
    font-weight: 600;
  }

  input {
    font-size: 13px;
  }
}

.responsive {
  max-width: 100% !important;
  width: 100%;
  height: auto;
}

.external-html-wrapper {
  background-color: #fff;
  padding: 15px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);

  img {
    max-width: 100% !important;
    width: 100%;
    height: auto;
  }
}

/*
suraj Added code*/
@media (max-width: 700px) {
  .col1 {
    flex-basis: auto;
  }
}
/*.mat-form-field :focus {
  border: 1px solid rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}*/



/*.mat-option-text:focus {
  border: 2px solid rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.mat-option:focus {
  border: 2px solid rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}*/
.submission-btn .submitSub-btn {
  height: 44px !important;
}
small, .text-danger, mat-error, .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow  {
  color: #B60202 !important;
}
/*.mat-form-field :focus {
  border: 2px solid rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  padding: 0px 0px 8px 0px !important;
  padding-top: 7px !important;
  padding-left: 17px !important;
  padding-right: 17px !important;
  margin-top: -0.9rem !important;
  margin-left: -0.8rem !important;
  margin-right: 13rem !important;
  margin-bottom: -0.9rem !important;
}*/
/*.mat-form-field :focus {
  border: 2px solid rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
   padding: 0px 0px 3px 0px !important;*/
 /* padding-top: 7px !important;
  padding-left: 17px !important;
  padding-right: 17px !important;
  margin-top: -0.9rem !important;
  margin-left: -0.8rem !important;
  margin-right: 13rem !important;
  margin-bottom: -0.9rem !important;*/
/*}*/
.tooltip-large .mat-tooltip {
  background-color: green !important; /* Set the background color to green */
  color: black !important; /* Set the text color to black */
  box-shadow: none !important; /* Remove any box shadow */
  border: none !important; /* Remove border if any */
  border-radius: 0 !important; /* Remove border radius */
  max-width: none !important; /* Remove default max-width limitation */
  padding: 10px; /* Add padding for readability */
  white-space: pre-line; /* Preserve line breaks */
}
